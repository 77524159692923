exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cost-tsx": () => import("./../../../src/pages/cost.tsx" /* webpackChunkName: "component---src-pages-cost-tsx" */),
  "component---src-pages-difficult-tsx": () => import("./../../../src/pages/difficult.tsx" /* webpackChunkName: "component---src-pages-difficult-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-method-tsx": () => import("./../../../src/pages/method.tsx" /* webpackChunkName: "component---src-pages-method-tsx" */)
}

